import React, { useState, useEffect } from 'react';
import Countdown from './CountDown';

const Dday = ({ name, month, day}) => {
  // useState Hooks
  const [state, setState] = useState({
    seconds: 0,
    hours: 0,
    minutes: 0,
    days: 0,
//    isItDday: false,
  });

  if (name === undefined || day === undefined || month === undefined) {
    name = "test"; 
    month = 0;
    day = 0; 
  }

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    setInterval(() => {
      const countdown = () => {
        const dateAtm = new Date();

        let dDay = new Date('2021/10/22/12:0:0:0');
        /*
        if (dateAtm > dDay) {
          dDay = new Date(currentYear + 1, month - 1, day);
        } else if (dateAtm.getFullYear() === dDay.getFullYear() + 1) {
          dDay = new Date(currentYear, month - 1, day);
        }*/

        const currentTime = dateAtm.getTime();
        const dTime = dDay.getTime();

        const timeRemaining = currentTime - dTime;

        let seconds = Math.floor(timeRemaining / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        
        if(timeRemaining < 0){
          seconds = 0;
          minutes = 0;
          hours = 0;
          days = 0;
        }

        seconds %= 60;
        minutes %= 60;
        hours %= 24;

        setState((prevState) => ({
          ...prevState,
          seconds,
          minutes,
          hours,
          days,
        //  isItDday,
        }));
      };
      countdown();
      /*
      if (!isItDday) {
        countdown();
      } else {
        setState((prevState) => ({
          ...prevState,
          isItDday: true,
        }));
      }*/
    }, 1000);
  }, [currentYear, day, month]);

  let d = new Date(currentYear, month - 1, day);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
      <Countdown countdownData={state} name={name} />
  );
};

export default Dday;
import React from 'react';

const Countdown = ({ countdownData, name }) => {
    return (
        <div>
            <div class='wrap'>
                <h1 className='heading'>
                    <span className='highlight'>{name}</span> 
                </h1>
            </div>
            <div class='wrap'>
                <div className='countdown-wrapper'>
                    <div className='countdown-box'>
                    {countdownData.days}
                    <span className='legend'>Days</span>
                    </div>
                    <div className='countdown-box'>
                    {countdownData.hours}
                    <span className='legend'>Hours</span>
                    </div>
                    <div className='countdown-box'>
                    {countdownData.minutes}
                    <span className='legend'>Minutes</span>
                    </div>
                    <div className='countdown-box'>
                    {countdownData.seconds}
                    <span className='legend'>Seconds</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Countdown;
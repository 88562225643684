import React, { Component } from "react";
import Slider from "react-slick";
import { partnerSlick } from "../../../page-demo/script";


const PortfolioList = [
    {
        image: 'partnerImage-1',
        category: '1',
        title: '1t',
        address: '/assets/images/portfolio/partners/portfolio-1.jpg'
    },
    {
        image: 'partnerImage-2',
        category: '2',
        title: '2t',
        address: '/assets/images/portfolio/partners/portfolio-2.jpg'
    },
    {
        image: 'partnerImage-3',
        category: '3',
        title: '3t',
        address: '/assets/images/portfolio/partners/portfolio-3.jpg'
    },
    {
        image: 'partnerImage-4',
        category: '4',
        title: '4t',
        address: '/assets/images/portfolio/partners/portfolio-4.jpg'
    },
    {
        image: 'partnerImage-5',
        category: '5',
        title: '5t',
        address: '/assets/images/portfolio/partners/portfolio-5.jpg'
    },
    {
        image: 'partnerImage-6',
        category: '6',
        title: '6t',
        address: '/assets/images/portfolio/partners/portfolio-6.jpg'
    },
    {
        image: 'partnerImage-7',
        category: '7',
        title: '7t',
        address: '/assets/images/portfolio/partners/portfolio-7.jpg'
    },
    {
        image: 'partnerImage-8',
        category: '8',
        title: '8t',
        address: '/assets/images/portfolio/partners/portfolio-8.jpg'
    },
    {
        image: 'partnerImage-9',
        category: '9',
        title: '9t',
        address: '/assets/images/portfolio/partners/portfolio-9.jpg'
    },
    {
        image: 'partnerImage-10',
        category: '10',
        title: '10t',
        address: '/assets/images/portfolio/partners/portfolio-10.jpg'
    }
]

class Partners extends Component{
    render(){
        const {title} = this.props;
        let description = '';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...partnerSlick}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <a href={`${value.address}`}>
                                        <div className="thumbnail-inner">
                                            <div className={`thumbnail ${value.image}`}></div>
                                            <div className={`bg-blr-image ${value.image}`}></div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Partners;
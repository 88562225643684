import React, { Component } from "react";
import {FaCoffee, FaTwitter ,FaInstagram ,FaFacebookF, FaYoutube} from "react-icons/fa";

const SocialShare = [
    {Social: <FaYoutube /> , link: 'https://www.youtube.com/channel/UCFX75_Fup_W9pRlWQWYL-sA'},
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/Noah-NFT-105330548567771'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/rratelcom/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/Noah_NFT_'},
    {Social: <FaCoffee /> , link: 'https://cafe.naver.com/noahnft'},
]

class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-12">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Quick Link</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/portfolio">Work</a></li>
                                                    <li><a href="/about">About</a></li>
                                                    <li><a href="/contact">Let's Talk</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>ConTact Us</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:noah@rratel.com">noah@rratel.com</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>
                                                    <font size = "4"> RATEL COMPANY, Ltd </font>
                                                    <font size = "2"> © RATEL, Ltd All Rights Reserved. </font>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;
import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import ServiceTwo from "../elements/service/ServiceTwo";

import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import Partners from "../component/HomeLayout/homeOne/Partners";

import BlogContent from "../elements/blog/BlogContent";
import Footer from "../component/footer/Footer";

import ModalVideo from 'react-modal-video';
import { videoTagString, VideoTag } from 'react-video-tag';

import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import CountDday from "../component/counters/CountDday"

//videoTagString({ src: '/assets/images/service/video.mp4', poster: '/assets/images/bg/bg-image-24.jpg' })

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        title: 'NOAH PROJECT',
        description: 'NOAH는 위변조가 만연하는 디지털 스마트 시대에서 NFT가 가지는 유일성과 무결성 특성을 활용하여 디지털 자산의 부가가치를 창출하고, 디지털 방주가 되겠다는 의미를 담고있다.',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]

class StudioAgency extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <Fragment>
                <div className="active-dark">
                <Helmet pageTitle="NOAH PROJECT" />
                {/* Start Header Area  */}
                <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div id="home" className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-8">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="video-inner">
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='Q7_f9Hj3_jI' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="video-background">
                                <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`}  src={`${"/assets/images/service/Noah_Trim.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}  
                </div>
                {/* End Slider Area   */}

                {/* Start Page Wrapper */}
                <div id="about" className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/aboutUs/aboutUsMain.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">ABOUT US</h4>
                                                    <ul className="liststyle">
                                                        <li>NOAH PLATFORM(노아의 방주)은 지구상에서 사라져 가는 문화, 동식물, 잊혀져서는 안되는 역사적 순간 등 가치 있는 모든 것을 위변조하지 못하게 막고자 하며 현재의 모습 그대로 기록하고 남기기 위해 존재합니다. </li>
                                                        <li>NOAH PLATFORM은 영화 제작에 투자자로서 참여해서, 작가, 시나리오, 제작사, 배급사 등을 직접 투표하고 선정하여 영화제작 펀딩기회를 창출하고, 세계로 뻗어나가는한국영화의 위상과 흥행의 주역이 된다. </li>
                                                        <li>NOAH PLATFORM은 희소가치가 있는 디지털 자산에게 위변조방지를 위한 NFT 기술을 적용하여 공증된 서비스를 제공하는 플랫폼이며 플랫폼에서 발행한 모든 디지털 자산과 NFT는 신뢰성과 유효성, 공신력을 갖게 됩니다.</li>
                                                        <li>NOAH PLATFORM이 소유한 NFT를 사회사업에 이바지하는 단체에 기부할 수 있으며, 이렇게 기부된 가치 있는 NFT가 다시 새로운 선한 가치를 창출할 수 있으며 우리 사회에 선한 영향력을 전파하는 구심점 역할을 하고자 합니다.</li>
                                                        <li>NOAH PLATFORM은 영화, 연예,스포츠,미술 등 다양한 프로젝트를 순차적으로 진행하여 NFT 세상과 나아가 현대의 디지털 세상에서 범지구적인방주로서의 역할을 수행할 것입니다.</li>
                                                    </ul>
                                                    <ul></ul>
                                                    <div>
                                                    <div className="purshase-btn" class='wrap'>
                                                        <div class='padding10'>
                                                            <a href="/assets/docs/NOAH_white_paper_v1.1.pdf" download className="rn-button-style--2 btn-solid">   White Paper   </a>
                                                            <a> - - </a>
                                                            <a href="http://noah-market.com" className="rn-button-style--2 btn-solid">   NFT MARKET   </a>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Service Area  */}
                <div id="service" className="service-area ptb--80  bg_image bg_image--3">
                    <div className="container">
                        <ServiceTwo />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Partner Area */}
                <div id="partner" className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--30">
                        <Partners title="Partner & Adviser"/>
                    </div>
                </div>
                {/* End Partner Area */}
                
                <div id="sale">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                
                {/* Start CounterUp Area */}
                
                <CountDday name="NOAH DAY" month="9" day="17" />
            
                <div id="saleCountDown">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                {/* End CounterUp Area */}


                {/* Start OurStory Area */}
                <div id="ourStory" className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55 mb_sm--30">
                        <Portfolio title="Our Story"/>
                    </div>
                </div>
                {/* End OurStory Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                {/* Start Footer Area  */}
                <div id="contact"></div>
                <Footer />
                {/* End Footer Area  */}        
                </div>        
            </Fragment>
        )
    }
}
export default StudioAgency;



//                {/* Start Brand Area */}
//                <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
 //                   <div className="container">
  //                      <div className="row">
   //                         <div className="col-lg-12">
    //                            <BrandTwo />
     //                       </div>
      //                  </div>
       //             </div>
        //        </div>
         //       {/* End Brand Area */}

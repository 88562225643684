import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";

const PortfolioList = [
    {
        image: 'ourStoryImage-1',
        category: '1',
        title: '1t',
        address: '/assets/images/portfolio/ourStory/ourStory-1.jpg'
    },
    {
        image: 'ourStoryImage-2',
        category: '2',
        title: '2t',
        address: '/assets/images/portfolio/ourStory/ourStory-2.jpg'
    },
    {
        image: 'ourStoryImage-3',
        category: '3',
        title: '3t',
        address: '/assets/images/portfolio/ourStory/ourStory-3.jpg'
    },
    {
        image: 'ourStoryImage-4',
        category: '4',
        title: '4t',
        address: '/assets/images/portfolio/ourStory/ourStory-4.jpg'
    },
]

class Partners extends Component{
    render(){
        const {title} = this.props;
        let description = '';
        return(
            <React.Fragment>
                <div className="portfolio-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <h2>{title}</h2>
                                    <p>{description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="portfolio-slick-activation mt--70 mt_sm--40">
                        <Slider {...portfolioSlick2}>
                            {PortfolioList.map((value , index) => (
                                <div className="portfolio" key={index}>
                                    <a href={`${value.address}`}>
                                        <div className="thumbnail-inner" href={`${value.address}`}>
                                            <div className={`thumbnail ${value.image}`}></div>
                                            <div className={`bg-blr-image ${value.image}`}></div>
                                        </div>
                                    </a>
                                </div>
                                
                            ))}
                        </Slider>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Partners;
import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'NFT 마켓',
        description: 'NOAH PLATFORM의 핵심적인 기능인 NFT 생성과 매매기능을 사용해 NFT 작품의 소유권을 구매하거나 판매하실 수 있습니다.'
    },
    {
        icon: <FiLayers />,
        title: '경매',
        description: '플랫폼 내에서 진행되는 NFT판매 및 경매 서비스 대행'
    },
    {
        icon: <FiUsers />,
        title: 'B2C 제휴사',
        description: '재단에서 직접 NFT 저작권자와 컨텐츠로 사용할 IP를 직접 계약'
    },
    {
        icon: <FiMonitor />,
        title: '펀딩/투자',
        description: '기관투자자 유입 기반 파생상품 도입, NFT 관련 상품을 한국 제도권에 편입되는 방안 검토'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = "마켓플레이스에서 연결되어 개인간의 거래(Peer-to-Peer, 'P2P') 및 기업과 개인간의 거래('Business to Consumer', B2C) NFT 거래가 가능해집니다.";
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
